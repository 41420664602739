import { Col, DatePicker, Form, Input, Row, Switch } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  COUPON_DURATION,
  COUPON_TYPE,
  DATE_FORMATS,
  NUMBER_FIELD_TYPES,
  SUBSCRIPTION_TYPE,
} from '../../../../../common/constants';
import { formValidatorRules, handleInput } from '../../../../../common/utils';
import NumberComponent from '../../../../../components/NumberComponent';
import SelectComponent from '../../../../../components/SelectComponent';
import LibrarySelect from '../../../../lists/modules/library/components/LibrarySelect';
import SubscriptionPlanSelect from '../../giftCard/components/SubscriptionPlanSelect';

const { Item } = Form;
const { TextArea } = Input;
const {
  price,
  whitespaceNotAllowed,
  required,
  couponCode,
  couponName,
  percentage,
} = formValidatorRules;

const CouponForm = ({
  form,
  onFinish,
  handleShowPrompt,
  initialValues,
  isView,
  couponDetail,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [startDate, setStartDate] = useState(initialValues?.startsOn || null);
  const [expireDate, setExpireDate] = useState(
    initialValues?.expiresOn || null,
  );
  const [isApplyToPlan, setIsApplyToPlan] = useState(false);
  const [couponType, setCouponType] = useState(COUPON_TYPE?.FLAT?.value);
  const isFlatCoupon = couponType === COUPON_TYPE?.FLAT?.value;

  useEffect(() => {
    const initialValueFun = () => {
      form?.setFieldsValue(initialValues);
      setCouponType(initialValues?.type || COUPON_TYPE?.FLAT?.value);
    };
    if (couponDetail?.metaData?.product) {
      setIsApplyToPlan(true);
    }
    setTimeout(initialValueFun, 300);
  }, []);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleShowPrompt}
        disabled={!!isView}
        onFinishFailed={onFinishFailed}
        className={`coupon-form ${isView ? 'common-view-form' : 'form'}`}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={12} xl={8}>
            <Item
              label="Code"
              name="code"
              rules={[
                { ...required, message: 'Please Enter Code!' },
                couponCode,
              ]}
            >
              <Input
                allowClear
                placeholder="Enter Code"
                maxLength={50}
                onInput={handleInput}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <Item
              label="Name"
              name="name"
              rules={[
                { ...required, message: 'Please Enter Name!' },
                couponName,
              ]}
            >
              <Input
                allowClear
                placeholder="Enter Name"
                maxLength={50}
                onInput={handleInput}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <Item
              label="Type"
              name="type"
              rules={[{ required: true, message: 'Please Select Type!' }]}
              initialValue={COUPON_TYPE?.FLAT?.value}
            >
              <SelectComponent
                placeholder="Select Type"
                options={map(COUPON_TYPE, (status) => ({
                  label: status?.label,
                  value: status?.value,
                }))}
                showSearch={false}
                onChange={(value) => {
                  form?.resetFields(['price']);
                  setCouponType(value);
                }}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <Item
              label={isFlatCoupon ? 'Amount' : 'Percentage (%)'}
              name="price"
              rules={[
                {
                  required: true,
                  message: `Please Enter ${
                    isFlatCoupon ? 'Amount' : 'Percentage'
                  }!`,
                },
                isFlatCoupon ? price : percentage,
              ]}
            >
              <NumberComponent
                allowClear
                placeholder={`Enter ${isFlatCoupon ? 'Amount' : 'Percentage'}`}
                type={NUMBER_FIELD_TYPES?.PRICE}
                isFractional={isFlatCoupon}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <Item label="Library Name" name="libraryId">
              <LibrarySelect libraryData={couponDetail?.library} />
            </Item>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <Item
              label="Library Commission"
              name="commission"
              rules={[whitespaceNotAllowed]}
            >
              <Input
                allowClear
                placeholder="Enter Library Commission"
                maxLength={15}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <Item
              label="Starts On"
              name="startsOn"
              rules={[
                {
                  required: true,
                  message: 'Please Select Starts On!',
                },
              ]}
            >
              <DatePicker
                format={DATE_FORMATS?.REGULAR}
                onChange={(date) => setStartDate(date)}
                disabledDate={(current) =>
                  (current &&
                    current < moment()?.subtract(1, 'days')?.endOf('day')) ||
                  (expireDate && current > expireDate.endOf('day'))
                }
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <Item
              label="Expires On"
              name="expiresOn"
              rules={[
                {
                  required: true,
                  message: 'Please Select Expires On!',
                },
              ]}
            >
              <DatePicker
                format={DATE_FORMATS?.REGULAR}
                onChange={(date) => setExpireDate(date)}
                disabledDate={(current) =>
                  (current &&
                    current < moment()?.subtract(1, 'days')?.endOf('day')) ||
                  (startDate && current < startDate.startOf('day'))
                }
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <Item label="Maximum Redemptions" name="redemptions">
              <NumberComponent
                allowClear
                placeholder="Enter Maximum Redemptions"
                isFractional={false}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <Item label="Minimum Order Value" name="orderValue">
              <NumberComponent
                allowClear
                placeholder="Enter Minimum Order Value"
                isFractional={false}
              />
            </Item>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <Item
              label="Duration"
              name="duration"
              rules={[
                {
                  required: true,
                  message: 'Please Select Duration!',
                },
              ]}
            >
              <SelectComponent
                showSearch={false}
                placeholder="Select Duration"
                options={map(COUPON_DURATION, (duration) => duration)}
              />
            </Item>
          </Col>
          <Col xs={24} className="mb-24 d-flex align-center">
            <Switch
              onChange={(checked) => setIsApplyToPlan(checked)}
              size="small"
              className="mr-8"
              checked={isApplyToPlan}
            />
            <span>Apply to specific plan</span>
          </Col>
          {isApplyToPlan && (
            <Col xs={24} sm={12} xl={8}>
              <Item
                label="Plan"
                name="plan"
                rules={[{ required: true, message: 'Please Select Plan!' }]}
              >
                <SubscriptionPlanSelect
                  type={SUBSCRIPTION_TYPE?.COUPON?.value}
                  planData={
                    couponDetail?.metaData?.product
                      ? {
                          ...couponDetail?.metaData?.product,
                          id: couponDetail?.metaData?.product?.id,
                        }
                      : null
                  }
                />
              </Item>
            </Col>
          )}
          <Col xs={24}>
            <Item
              label="Description"
              name="description"
              rules={[whitespaceNotAllowed]}
            >
              <TextArea
                rows={4}
                allowClear
                placeholder="Enter Description"
                showCount
                maxLength={900}
              />
            </Item>
          </Col>
          <Col xs={24}>
            <Item label="Note" name="note" rules={[whitespaceNotAllowed]}>
              <TextArea
                rows={3}
                allowClear
                placeholder="Enter Note"
                showCount
                maxLength={700}
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CouponForm;
